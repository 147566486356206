import React from "react";
import Seo from "./components/seo/seo";
import { graphql } from "gatsby";
import "../../styles/app.css";

function Mentions({ data }) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html:
          data.contentfulMentionsLegalesDescriptionTextNode.childMarkdownRemark
            .html,
      }}
    />
  );
}

export const query = graphql`
  query MentionsPageQuery {
    contentfulMentionsLegalesDescriptionTextNode {
      childMarkdownRemark {
        html
      }
    }
  }
`;

export default Mentions;

export const Head = () => <Seo tilte="Mentions Légales" />;
